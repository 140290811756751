"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        adminApiBaseUrl: 'https://atg9nr0hg8.execute-api.us-east-1.amazonaws.com/dev/admin/graphql',
        foodOpsApiBaseUrl: 'https://atg9nr0hg8.execute-api.us-east-1.amazonaws.com/dev/foodops/graphql',
        siteStaffApiBaseUrl: 'https://atg9nr0hg8.execute-api.us-east-1.amazonaws.com/dev/site-staff/graphql',
        patientApiBaseUrl: 'https://atg9nr0hg8.execute-api.us-east-1.amazonaws.com/dev/patient/v2/graphql',
        commonApi: 'https://atg9nr0hg8.execute-api.us-east-1.amazonaws.com/dev/common',
        apiKey: 'xablauapikeyxablau123-dev',
        webSocketBaseUrl: 'wss://002hggu6vg.execute-api.us-east-1.amazonaws.com/dev'
    },
    cloudfront: 'd3blgs78fm7jw.cloudfront.net',
    cognito: {
        adminUserPoolId: 'us-east-1_PYoIdMpIn',
        adminUserPoolClientId: '3mutt01e7k5tmanm9h8eifqopa',
        foodOpsUserPoolId: 'us-east-1_ZKSUKDvZJ',
        foodOpsUserPoolClientId: '6nfslo83b783sse7u9ls67jjvm',
        patientUserPoolId: 'us-east-1_pnpxxjMvQ',
        patientUserPoolClientId: '4vrmcb0bibeeg6843uan44jtl',
        siteStaffUserPoolId: 'us-east-1_8jvNpcWoL',
        siteStaffUserPoolClientId: '2dg33djdl0og05685ojhetrq98'
    },
    pinpoint: '42d49ab8c5ac41a1a7dfed51114ca84d',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
